import React from "react";
import Tempapp from "./components/Tempapp";

const App = () => {
  return (
    <>
      <Tempapp />
    </>
  );
};

export default App;
